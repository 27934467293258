<template>
    <div class="about full-width" v-if="translates && translates[langUrl]">
        <div
            class="about__main-image">
            <div class="about__main-image__opacity"></div>
            <div class="about__main-image__container">
                <!-- <div v-if="!isDesktop" class="main-page__main-image__container__filter">
                    <v-text-field
                        label="Направление"
                    />
                </div> -->
                <div class="about__main-image__container__text">{{ translates[langUrl].pageTitle[lang] }}</div>
                <!-- <div class="about__main-image__container__comment">6 простых шагов!</div> -->
                <!-- <div v-if="pageTemplate.main.links" class="main-page__main-image__container__directions">
                    <template v-for="(link, index) in pageTemplate.main.links">
                        <div
                            :key="`main-links-${index}`"
                            class="about__main-image__container__direction"
                            @click="$router.push(link.link)">{{ link.title }}</div>
                    </template>
                </div> -->
            </div>
        </div>
        <div class="about__welcome">
            <div class="about__welcome__pictures">

            </div>
            <div class="about__welcome__text">
                <div class="about__welcome__text__title">
                    {{ translates[langUrl].welcome_title[lang] }}
                </div>
                <div class="about__welcome__text__description">
                   {{ translates[langUrl].welcome_description[lang] }}
                </div>
                <!-- <div class="about__welcome__text__description" style="margin-bottom: 45px">
                    TrovaTrip позволяет экспертам, творческим людям и предпринимателям организовывать поездки со своими сообществами. Кроме того, у хозяев есть возможность обучать своему ремеслу, проводя семинары лично. Фотография в Португалии? Девочки едут на Галапагосы? Нисходящие собаки в Доминиканской Республике? Да, да и ещё раз да.
                </div> -->
                <div class="about__welcome__text__comment">
                   {{ translates[langUrl].welcome_comment[lang] }}
                </div>
            </div>
        </div>
        <div class="about__buttons">
            <div class="about__buttons__more about__buttons__organizer">
                <div class="about__buttons__more__text">
                    <div class="about__buttons__more__title">{{ translates[langUrl].offer_first_title[lang] }}</div>
                    <div class="about__buttons__more__description">{{ translates[langUrl].offer_first_description[lang] }}</div>
                </div>
                <BaseButton
                    class="about__buttons__more__button button-fill"
                    @click="goTo('blogger-registration')"
                >{{ translates[langUrl].button_more[lang] }}</BaseButton>
            </div>
            <div class="about__buttons__more about__buttons__user">
                <div class="about__buttons__more__text">
                    <div class="about__buttons__more__title">{{ translates[langUrl].offer_second_title[lang] }}</div>
                    <div class="about__buttons__more__description">{{ translates[langUrl].offer_second_description[lang] }}</div>
                </div>
                <BaseButton
                    class="about__buttons__more__button"
                    @click="goTo('blogger-registration')"
                >{{ translates[langUrl].button_more[lang] }}</BaseButton>
            </div>
        </div>
        <div class="about__benefits">
            <div class="about__benefits__title">{{ translates[langUrl].advantages_title[lang] }}</div>
            <div class="about__benefits__description">{{ translates[langUrl].advantages_comment[lang] }}</div>
            <div class="about__benefits__items-container">
                <!-- <template v-for="(benefit, index) in trip.benefits">
                    <div :key="'benefit_' + index" class="about__benefits__items-container__item">
                        <div class="about__benefits__items-container__item__icon">
                            <img v-if="formattedBenefitsTypes(index) && formattedBenefitsTypes(index)[0]" :src="`${ imageSrc(formattedBenefitsTypes(index)[0].picture) }`" class="detailHost__benefits__items-container__item__icon__picture" />
                        </div>
                        <div class="about__benefits__items-container__item__text-container">
                            <div class="about__benefits__items-container__item__title"> {{ benefit.title }}</div>
                            <div class="about__benefits__items-container__item__description"> {{ benefit.description }}</div>
                        </div>
                    </div>
                </template> -->
                <div class="about__benefits__items-container__item">
                    <div class="about__benefits__items-container__item__icon">
                        <img src="../assets/about/benefits/Bus.svg" />
                    </div>
                    <div class="about__benefits__items-container__item__text-container">
                        <div class="about__benefits__items-container__item__title">{{ translates[langUrl].advantage_1_title[lang] }}</div>
                        <div class="about__benefits__items-container__item__description">{{ translates[langUrl].advantage_1_comment[lang] }}</div>
                    </div>
                </div>
                <div class="about__benefits__items-container__item">
                    <div class="about__benefits__items-container__item__icon">
                        <img src="../assets/about/benefits/Marketing.svg" />
                    </div>
                    <div class="about__benefits__items-container__item__text-container">
                        <div class="about__benefits__items-container__item__title">{{ translates[langUrl].advantage_2_title[lang] }}</div>
                        <div class="about__benefits__items-container__item__description">{{ translates[langUrl].advantage_2_comment[lang] }}</div>
                    </div>
                </div>
                <div class="about__benefits__items-container__item">
                    <div class="about__benefits__items-container__item__icon">
                        <img src="../assets/about/benefits/Experts.svg" />
                    </div>
                    <div class="about__benefits__items-container__item__text-container">
                        <div class="about__benefits__items-container__item__title">{{ translates[langUrl].advantage_3_title[lang] }}</div>
                        <div class="about__benefits__items-container__item__description">{{ translates[langUrl].advantage_3_comment[lang] }}</div>
                    </div>
                </div>
                <div class="about__benefits__items-container__item">
                    <div class="about__benefits__items-container__item__icon">
                        <img src="../assets/about/benefits/Planer.svg" />
                    </div>
                    <div class="about__benefits__items-container__item__text-container">
                        <div class="about__benefits__items-container__item__title">{{ translates[langUrl].advantage_4_title[lang] }}</div>
                        <div class="about__benefits__items-container__item__description">{{ translates[langUrl].advantage_4_comment[lang] }}</div>
                    </div>
                </div>
                <div class="about__benefits__items-container__item">
                    <div class="about__benefits__items-container__item__icon">
                        <img src="../assets/about/benefits/Quiz.svg" />
                    </div>
                    <div class="about__benefits__items-container__item__text-container">
                        <div class="about__benefits__items-container__item__title">{{ translates[langUrl].advantage_5_title[lang] }}</div>
                        <div class="about__benefits__items-container__item__description">{{ translates[langUrl].advantage_5_comment[lang] }}</div>
                    </div>
                </div>
                <div class="about__benefits__items-container__item">
                    <div class="about__benefits__items-container__item__icon">
                        <img src="../assets/about/benefits/Booking.svg" />
                    </div>
                    <div class="about__benefits__items-container__item__text-container">
                        <div class="about__benefits__items-container__item__title">{{ translates[langUrl].advantage_6_title[lang] }}</div>
                        <div class="about__benefits__items-container__item__description">{{ translates[langUrl].advantage_6_comment[lang] }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="about__banner">
            <div class="about__banner__text">{{ translates[langUrl].pinkBlock_title[lang] }}</div>
            </div>
        <div class="about__role">
            <div class="about__role__item about__role__organizer">
                <div class="about__role__item__picture about__role__organizer__picture"></div>
                <div class="about__role__item__title about__role__organizer__title">{{ translates[langUrl].role_organizers_title[lang] }}</div>
                <div class="about__role__item__text about__role__organizer__text">{{ translates[langUrl].role_organizers_description[lang] }}</div>
                <BaseButton
                    class="about__role__item__button"
                    @click="goTo('operator')"
                >{{ translates[langUrl].button_more[lang] }}</BaseButton>
            </div>
            <div class="about__role__item about__role__experts">
                <div class="about__role__item__picture about__role__experts__picture"></div>
                <div class="about__role__item__title about__role__experts__title">{{ translates[langUrl].role_experts_title[lang] }}</div>
                <div class="about__role__item__text about__role__experts__text">{{ translates[langUrl].role_experts_description[lang] }}</div>
                <BaseButton
                    class="about__role__item__button"
                    @click="goTo('experts')"
                >{{ translates[langUrl].button_more[lang] }}</BaseButton>
            </div>
            <div class="about__role__item about__role__traveler">
                <div class="about__role__item__picture about__role__traveler__picture"></div>
                <div class="about__role__item__title about__role__traveler__title">{{ translates[langUrl].role_travelers_title[lang] }}</div>
                <div class="about__role__item__text about__role__traveler__text">{{ translates[langUrl].role_travelers_description[lang] }}</div>
                <BaseButton
                    class="about__role__item__button"
                    @click="goTo('trip-list')"
                >{{ translates[langUrl].button_more[lang] }}</BaseButton>
            </div>
        </div>
        <!-- <div class="about__reviews">
            <div class="about__reviews__title">{{ translates[langUrl].reviews_title[lang] }}</div>
            <div class="about__reviews__container">
                <Review
                    class="about__reviews__container__review"
                    :review="{
                        user: {
                            firstname: 'Евгений',
                            lastname: 'Александров',
                            avatar: ''
                        },
                        date: '8 ноября 2021',
                        rating: {
                            overall: 5
                        },
                        text: 'Сам тур был немного амбициозным — во многих местах не хватало времени (например, в Риме и Флоренции). С другой стороны...'
                    }"
                />
                <Review
                    class="about__reviews__container__review"
                    :review="{
                        user: {
                            firstname: 'Александр',
                            lastname: 'Волков',
                            avatar: ''
                        },
                        date: '8 ноября 2021',
                        rating: {
                            overall: 5
                        },
                        text: 'Эта поездка была превосходной, я определенно намерен снова путешествовать с бесстрашным и рекомендовал бы эту поездку...'
                    }"
                />
                <Review
                    class="about__reviews__container__review"
                    :review="{
                        user: {
                            firstname: 'Евгения',
                            lastname: 'Вулич',
                            avatar: '2022/2/4/1643978490464-6549.png'
                        },
                        date: '8 ноября 2021',
                        rating: {
                            overall: 5
                        },
                        text: 'Сам тур был немного амбициозным — во многих местах не хватало времени (например, в Риме и Флоренции). С другой стороны...'
                    }"
                />
            </div>
            <BaseButton>Открыть все отзывы</BaseButton>
        </div> -->
        <div class="about__reviews">
            <div class="about__reviews__title">{{ translates[langUrl].reviews_title[lang] }}</div>
            <div class="about__reviews__container">
                <template v-for="(review, index) in reviews.items">
                    <Review
                        :key="'review' + index"
                        :review="review"
                    />
                </template>
            </div>
            <BaseButton @click="goTo('reviews-list')">{{ translates[langUrl].button_allReviews[lang] }}</BaseButton>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { isMobile, imageSrc, SplideSlideCounter } from '@/helpers';
    import BaseButton from '../components/common/BaseButton.vue';
    import Review from '../components/common/Review.vue'

    import moment from '@/plugins/moment';
import trips from '../store/modules/trips';
    moment.locale('ru');

    export default {
        name: 'About',
        metaInfo() {
            return {
                title: this.translates[this.langUrl].browserTitle[this.lang]
            }
        },
        components: {
            BaseButton,
            Review,
        },
        data: () => ({
            timerDate: '',
            timer: null,
            isDesktop: false,
            imageSrc,
            langUrl: '/about'
        }),
        async mounted() {
            this.$root.$emit('preloaderShow');
            await store.dispatch('reviews/fetch', { limit: 3, page: 1 });
            this.$root.$emit('preloaderHide');
        },
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            ...mapState('reviews', {
                reviews: state => state.entities
            }),
        },
        methods: {
            async authorization() {
                this.$root.$emit('popupLoginShow');
            },
            async goTo(name) {
                await this.$router.push({ name });
            },
        },
    };
</script>

<style lang="scss">
.about {
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    // display: flex;
    // flex-direction: column;
    &__main-image {
        position: relative;
        width: 100%;
        height: 440px;
        background: url(../assets/about/About_1.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        @media all and (max-width: 768px) {
            height: 356px;
            background-position: center;
        }
        &__opacity {
            position: absolute;
            width: 100%;
            height: 100%;
            // background: #543919;
            background: #000;
            opacity: 0.5;
        }
        &__container {
        position: absolute;
        width: 100%;
        height: 440px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        margin-bottom: 40px;
            @media all and (max-width: 768px) {
                height: 356px;
            }
            &__text {
                font-weight: bold;
                font-size: 56px;
                line-height: 150%;
                letter-spacing: -0.02em;
                text-align: center;
                @media all and (max-width: 768px) {
                    font-weight: 800;
                    font-size: 24px;
                    line-height: 150%;
                    letter-spacing: -0.02em;
                }
            }
            &__comment {
                font-size: 24px;
                margin-top: 8px;
                line-height: 150%;
                letter-spacing: -0.02em;
                @media all and (max-width: 768px) {
                    font-size: 16px;
                    text-align: center;
                }
            }
        }
    }
    &__welcome {
        display: flex;
        flex-direction: row;
        max-width: 1280px;
        margin: 50px auto 50px;
        @media all and (max-width: 1280px){
            padding: 0 20px;
        }
        @media all and (max-width: 768px){
            flex-direction: column;
            max-width: calc(100% - 40px);
        }     
        &__pictures {
            min-width: 730px;
            min-height: 570px;
            background-image: url(../assets/about/About-photos.png);
            background-position: center;
            background-size: contain;
            margin-right: 30px;
            @media all and (max-width: 1200px) {
                min-width: 500px;
                min-height: 400px;
                margin: auto 20px auto 0;
            }
            // @media all and (max-width: 1024px) {
            //     min-height: 200px;
            //     min-width: 300px;
            //     margin: auto 50px auto 0;
            // }
            @media all and (max-width: 768px){
                display: none;
            }
        }
        &__text {
            max-width: 530px;
            &__title {
                font-weight: 700;
                font-size: 28px;
                line-height: 143.02%;
                letter-spacing: -0.02em;
                color: #273155;
                margin-bottom: 32px;
                @media all and (max-width: 768px){
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 130%;
                    margin-bottom: 16px;
                }
            }
            &__description {
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin-bottom: 50px;
                @media all and (max-width: 768px){
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    margin-bottom: 24px;
                }
            }
            &__comment {
                font-weight: 500;
                font-size: 20px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #3A4256;
                @media all and (max-width: 768px){
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 150%;
                }
            }
        }
    }
    &__buttons {
        display: flex;
        flex-direction: row;
        max-width: 1280px;
        margin: 50px auto 50px;
        @media all and (max-width: 768px){
            flex-direction: column;
            max-width: calc(100% - 40px);
            margin: 40px auto;
        }  
        &__more {
            display: flex;
            flex-direction: row;
            width: 100%;
            min-height: 115px;
            padding: 28px;
            background: #F6F7F9;
            border-radius: 12px;
            justify-content: space-between;
            margin-top: 70px;
            @media all and (max-width: 768px) {
                flex-direction: column;
                min-height: 220px;
                background: #F6F7F9;
                border-radius: 12px;
                padding: 24px;
                margin: 24px 0 0 0;
            }
            &__text {
                display: flex;
                flex-direction: column;
                font-family: Inter;
                font-style: normal;
                letter-spacing: -0.02em;
                color: #3A4256;
            }
            &__title {
                font-weight: 600;
                font-size: 18px;
                line-height: 140%;
                margin-bottom: 10px;
                letter-spacing: -0.02em;
                color: #3A4256;
                @media all and (max-width: 768px) {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 140%;
                    margin-bottom: 8px;
                }
            }
            &__description {
                font-weight: normal;
                font-size: 12px;
                line-height: 150%;
                @media all and (max-width: 768px) {
                    // font-size: 14px;
                    // line-height: 150%;
                    letter-spacing: -0.02em;
                    color: #3A4256;
                    margin-bottom: 20px;
                }
            }
            &__button {
                width: 180px;
                height: 52px !important;
                @media all and (max-width: 768px) {
                    width: 100%;
                }
            }
        }
        &__organizer {
            margin-right: 32px;
            @media all and (max-width: 768px) {
                margin-right: 0;
            }
        }
    }
    &__benefits {
        max-width: 1280px;
        margin: 120px auto 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media all and (max-width: 1280px){
            padding: 0 20px;
        }
        @media all and (max-width: 768px) {
            max-width: calc(100% - 40px);
            margin: 64px auto 56px;
        }
        &__title {
            font-weight: 700;
            font-size: 38px;
            line-height: 46px;
            color: #273155;
            margin-bottom: 20px;
            @media all and (max-width: 768px) {
                font-weight: 600;
                font-size: 28px;
                line-height: 136%;
                text-align: center;
            }
        }
        &__description {
            font-weight: 500;
            font-size: 20px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #3A4256;
            margin-bottom: 50px;
            @media all and (max-width: 768px) {
                font-weight: 500;
                font-size: 18px;
                line-height: 150%;
                text-align: center;
            }
        }
        &__items-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            @media all and (max-width: 1280px) {
                justify-content: center;
            }
            @media all and (max-width: 768px) {
                // gap: 24px;
            }
            &__item {
                width: 610px;
                min-height: 149px;
                display: flex;
                flex-direction: row;
                // gap: 10px;
                margin-bottom: 40px;
                @media all and (max-width: 768px) {
                    flex-direction: column;
                    align-items: center;
                    // justify-content: center;
                    padding: 0;
                    width: 100%;
                    margin-bottom: 24px;
                }
                &__icon {
                    width: 65px;
                    height: 65px;
                    // background: #FFE4F0;
                    border-radius: 10px;
                    box-shadow: 0px 4px 22px rgba(44, 47, 56, 0.05);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 30px;
                    @media all and (max-width: 768px) {
                        margin-bottom: 10px;
                    }
                    &__picture {
                        width: 24px;
                        height: 24px;
                    }
                }
                &__title {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 150%;
                    letter-spacing: -0.02em;
                    margin: 18px 0 20px;
                    @media all and (max-width: 768px) {
                        font-size: 16px;
                        text-align: center;
                        margin-bottom: 6px;
                    }
                }
                &__description {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 150%;
                    letter-spacing: -0.02em;
                    color: #616D89;
                    max-width: 520px;
                    @media all and (max-width: 768px) {
                        max-width: 100%;
                        font-size: 14px;
                        text-align: center;
                    }
                }
            }
        }
    }
    &__banner {
        width: 100%;
        height: 220px;
        background-image: url(../assets/about/Banner.jpg);
        background-position: center;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        @media all and (max-width: 768px) {
            background-image: url(../assets/about/Banner-mini.jpg);
            height: 160px;
        }
        &__text {
            font-weight: 600;
            font-size: 52px;
            line-height: 63px;
            color: #FFFFFF;
            text-align: center;
            @media all and (max-width: 768px) {
                font-weight: 800;
                font-size: 24px;
                line-height: 150%;
            }
        }
        
    }
    &__role{
        max-width: 1280px;
        margin: 50px auto;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        @media all and (max-width: 768px){
            flex-direction: column;
            max-width: calc(100% - 40px);
        }
        &__item {
            max-width: 405px;
            margin-bottom: 16px;
            @media all and (max-width: 1300px){
                margin-right: 10px;
            }
            @media all and (max-width: 768px){
                min-width: 100%;
                margin-right: 0;
            }
            &__picture {
                // min-width: 660px;
                // min-height: 400px;
                min-width: 405px;
                min-height: 405px;
                background-position: center;
                background-size: cover;
                margin-bottom: 20px;
                border-radius: 10px;
                @media all and (max-width: 768px){
                    min-width: 100%;
                }
            }
            &__title {
                font-weight: 700;
                font-size: 36px;
                line-height: 44px;
                color: #273155;
                margin-bottom: 16px;
                @media all and (max-width: 768px){
                    font-size: 24px;
                    line-height: 29px;
                }
            }
            &__text {
                min-height: 135px;
                font-weight: 400;
                font-size: 18px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin-bottom: 20px;
                @media all and (max-width: 768px){
                    font-size: 14px;
                    line-height: 150%;
                    min-height: 100px;
                }
            }
            &__button {
                width: 240px;
                height: 52px !important;
                @media all and (max-width: 768px){
                    width: 100%;
                }
            }
        }
        &__organizer__picture {
            background-image: url(../assets/about/Organizers.jpg);
        }
        &__experts__picture {
            background-image: url(../assets/about/About-traveler.png);
        }
        &__traveler__picture {
            background-image: url(../assets/about/About-organizer.png);
        }
    }
    &__reviews {
        max-width: 1280px;
        margin: 120px auto 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media all and (max-width: 768px){
            flex-direction: column;
            max-width: calc(100% - 40px);
            margin: 80px auto;
        }
        &__title {
            font-weight: 600;
            font-size: 28px;
            line-height: 130%;
            letter-spacing: -0.02em;
            color: #273155;
            margin-bottom: 30px;
        }
        &__container {
            display: flex;
            flex-direction: row;
            // flex-wrap: wrap;
            justify-content: space-around;
            margin-bottom: 30px;
            @media all and (max-width: 768px){
                flex-direction: column;
                margin-bottom: 10px;
            }
            &__review {
                margin-bottom: 20px;
            }
        }
        
    }
}

</style>